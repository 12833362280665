.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%);
}

.App-header {
  background: linear-gradient(160deg, #2b5876 0%, #4e4376 100%);
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.App-header h1 {
  margin: 0;
  font-size: 2.8rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.App-header p {
  margin: 1rem 0 0;
  font-size: 1.3rem;
  opacity: 0.9;
  max-width: 600px;
  line-height: 1.6;
  font-weight: 300;
}

.App-main {
  flex: 1;
  padding: 3rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  margin-top: -2rem;
  position: relative;
  z-index: 1;
}

.App-footer {
  background: linear-gradient(160deg, #2b5876 0%, #4e4376 100%);
  color: white;
  padding: 2rem;
  margin-top: auto;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 1.5rem;
}

.footer-links a {
  color: white;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  position: relative;
}

.footer-links a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: white;
  transition: width 0.3s ease;
}

.footer-links a:hover {
  opacity: 1;
}

.footer-links a:hover::after {
  width: 100%;
}

.copyright {
  margin: 0;
  opacity: 0.7;
  font-size: 0.95rem;
  font-weight: 300;
  letter-spacing: 0.5px;
}

@media (max-width: 768px) {
  .App-header {
    padding: 2rem 1.5rem;
  }

  .App-header h1 {
    font-size: 2.2rem;
  }

  .App-header p {
    font-size: 1.1rem;
  }

  .App-main {
    padding: 2rem 1rem;
    margin-top: -1rem;
    border-radius: 8px;
  }

  .footer-links {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer-links a {
    font-size: 1rem;
  }
}
