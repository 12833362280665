.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%) translateY(100px);
  background-color: #323232;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.toast.show {
  transform: translateX(-50%) translateY(0);
  opacity: 1;
}

.toast::before {
  content: '✓';
  display: inline-block;
  margin-right: 8px;
  font-weight: bold;
}

/* 添加响应式设计 */
@media (max-width: 768px) {
  .toast {
    width: 90%;
    text-align: center;
  }
}

/* 添加深色模式支持 */
@media (prefers-color-scheme: dark) {
  .toast {
    background-color: #424242;
  }
}

.toast.success {
  background-color: #43a047;
}

.toast.error {
  background-color: #e53935;
}

.toast.info {
  background-color: #1e88e5;
}

.toast.success::before {
  content: '✓';
}

.toast.error::before {
  content: '✕';
}

.toast.info::before {
  content: 'ℹ';
} 