:root {
  --primary-color: #2196f3;
  --secondary-color: #64b5f6;
  --background-color: #f5f5f5;
  --card-background: #ffffff;
  --text-color: #333333;
  --border-radius: 12px;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

.converter-wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.converter-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 40px;
}

.converter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
  gap: 16px;
}

.current-time {
  display: flex;
  align-items: center;
  gap: 12px;
}

.time-label {
  font-size: 14px;
  color: #666;
}

.time-value {
  font-family: monospace;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
}

.unit-selector, .timezone-selector {
  display: flex;
  align-items: center;
  gap: 12px;
}

.unit-label, .timezone-label {
  font-size: 14px;
  color: #666;
}

.timezone-select {
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  color: #333;
  background: #fff;
  cursor: pointer;
}

.timezone-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.toggle-group {
  display: flex;
  gap: 1px;
  background: #eee;
  padding: 2px;
  border-radius: 6px;
}

.toggle-button {
  border: none;
  background: transparent;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  color: #666;
  transition: all 0.2s;
}

.toggle-button.active {
  background: #fff;
  color: #333;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.converter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

.converter-box {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.converter-box h3 {
  margin: 0 0 16px 0;
  font-size: 16px;
  color: #333;
}

.input-row {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
}

.converter-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.converter-input:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.1);
}

.convert-button {
  padding: 8px 16px;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s;
}

.convert-button:hover {
  background: #357abd;
}

.result-row {
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  padding: 12px;
  border-radius: 6px;
}

.result-text {
  flex: 1;
  font-family: monospace;
  font-size: 14px;
  color: #333;
}

.copy-button {
  padding: 6px 12px;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  color: #666;
  transition: all 0.2s;
}

.copy-button:hover {
  background: #f5f5f5;
  border-color: #ccc;
}

.copy-button.small {
  padding: 4px 8px;
  font-size: 12px;
}

/* Documentation Styles */
.documentation {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 32px;
  margin-top: 40px;
}

.documentation h2 {
  font-size: 24px;
  color: #333;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  border-bottom: 2px solid #4a90e2;
}

.doc-section {
  margin-bottom: 40px;
}

.doc-section:last-child {
  margin-bottom: 0;
}

.doc-section h3 {
  font-size: 20px;
  color: #333;
  margin: 0 0 16px 0;
}

.doc-section p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin: 0 0 16px 0;
}

.doc-section ul {
  margin: 0;
  padding-left: 24px;
}

.doc-section li {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 8px;
}

.faq-list {
  display: grid;
  gap: 24px;
}

.faq-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
}

.faq-item h4 {
  font-size: 16px;
  color: #333;
  margin: 0 0 12px 0;
}

.faq-item p {
  font-size: 15px;
  line-height: 1.6;
  color: #555;
  margin: 0;
}

@media (max-width: 768px) {
  .converter-wrapper {
    padding: 12px;
  }

  .converter-container,
  .documentation {
    padding: 16px;
  }

  .converter-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .converter-grid {
    grid-template-columns: 1fr;
  }

  .input-row {
    flex-direction: column;
  }

  .convert-button {
    width: 100%;
  }

  .documentation h2 {
    font-size: 20px;
    margin-bottom: 24px;
  }

  .doc-section h3 {
    font-size: 18px;
  }

  .doc-section p,
  .doc-section li {
    font-size: 15px;
  }
}