.language-selector {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: 1000;
}

.language-select {
  padding: 0.7rem 2.5rem 0.7rem 1rem;
  font-size: 1rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  outline: none;
  min-width: 140px;
  appearance: none;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  font-weight: 500;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 1em;
}

.language-select:hover {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-1px);
}

.language-select:focus {
  border-color: rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.2);
}

.language-select option {
  padding: 0.8rem;
  background-color: #2b5876;
  color: white;
}

@media (max-width: 768px) {
  .language-selector {
    top: 1rem;
    right: 1rem;
  }

  .language-select {
    padding: 0.6rem 2rem 0.6rem 0.8rem;
    font-size: 0.9rem;
    min-width: 120px;
  }
}
